<template>
    <div class="container-fluid">
       <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadodebancos') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <bancos-estados :bancos="bancos.length" />
              </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas" :datos="bancos" :filtros="filtros"
                        :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerBancos">
                    </tabla-datos>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import BancosEstados from './listado/BancosEstados.vue';
import TablaDatos from '../../TablaDatos.vue';
export default {
    components: {
    "bancos-estados": BancosEstados,
    "tabla-datos": TablaDatos, 
},
    data() {
        return {
        total:'',
        bancos: [],
        parametros: null, 
        filtrosBuscador: ['nombre_banco'], 
        filtros: { 
            'nombre': { value: '', matchMode: 'contains' },
            'nombre_banco': { value: '', matchMode: 'contains' },
        },
        columnas: [
            {
                header: 'Nombre',
                field: 'nombre_banco',
                sortable: true,
            },
            {
                header: 'Localidad',
                field: 'localidad_banco',
                sortable: true,
            },
            {
                header: 'Acciones',
                data: null,
                acciones: [
                    {
                        nombre: 'Editar',
                        class: 'editar',
                        tipo: 'default',
                        nombreId: 'id_banco',
                        icono: 'far fa-edit',
                        // Debe ser arrow function para que this sea este componente
                        accion: (id) => {
                            console.log('Editar banco ' + id);
                            //this.obtenerUsuario(id);
                            this.$router.push({
                                name: 'Banco',
                                params: {
                                    id
                                }
                            });
                        }
                    },
                    {
                        nombre: 'Eliminar',
                        class: 'eliminar',
                        tipo: 'danger',
                        nombreId: 'id_banco',
                        icono: 'far fa-trash-alt',
                        // Debe ser arrow function para que this sea este componente
                        accion: (id) => {
                            console.log('Eliminar banco ' + id);
                            if (confirm('Desea eliminar al banco')) {
                                this.eliminar(id);
                            }
                        }
                    },
                ]
            }
        ]
     }
    },
    methods: {
        async obtenerBancos(parametros) {
            /* get  modulos/pwgsapi/index.php/bancos */
            const api = new PwgsApi;
            const params = parametros;
            const banca = await api.get('bancos', params);
            this.bancos = banca.datos;
            this.total = banca.n_total_registros;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.localidad");
            this.columnas[2].header = this.$t("general.acciones");

        },
        async eliminar(id)
        {
            /*  modulos/pwgsapi/index.php/bancos/:id */
            console.log('eliminando banco ' + id + '...');
            const api = new PwgsApi;
            await api.delete('bancos/' + id);
            this.obtenerBancos();
        },
    },
    activated()
    {
        this.obtenerBancos();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>